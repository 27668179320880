import React from "react";
import AjoBannerImg from "../../assets/images/svgs/ajo-banner.svg";
import TopAjoBanner from "./TopAjoBanner";

function AjoBanner() {
  return (
  <>
    <TopAjoBanner />
    <div className="flex md:flex-row flex-col  justify-between items-center bg-[#262730] rounded-3xl overflow-hidden">
      <div className="w-full lg:w-2/5 md:w-3/5 text-white p-9 m-10">
        <div className="flex items-center gap-1">
          <hr style={{ width: "12px" }} className="border-green-600 border-1" />{" "}
          <small className="text-[#e0d9d9] text-center text-sm tracking-widest">SMASH YOUR SAVING GOALS</small>
        </div>

        <h1 className=" text-2xl font-semibold my-5">With our group ajo feature, save with friends and family.</h1>
        <p className="text-[#c6bebe]">
          It is convenient and flexible way to smash your savings goal and built
          a better future
        </p>
      </div>
      <div>
        <img src={AjoBannerImg} width={450} height={300} alt="" />
      </div>
    </div>
    </>
  );
}

export default AjoBanner;
