import React from "react";
// import { Link } from 'react-router-dom'
import ImageCard from "./ImageCard";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PreviousArrow from "./PreviousArrow";
import { carouselData } from "../../../data/social-media-contacts";

function BlogCarousel() {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 100,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: true,
          nextArrow: false,
          prevArrow: false,

        },
      },
    ],
  };

  return (
    <div className="relative mb-40">
      <Slider {...settings}>
        {carouselData.map((el, i) => (
          <ImageCard
            key={i}
            img={el.img}
            headline={el.headline}
            time={el.time} 
          />
        ))}
      </Slider>
    </div>
  );
}

export default BlogCarousel;

