/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import Download from "../components/Download";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";
import OurPartner from "../components/OurPartner";
import AjoBanner from "../components/AjoBanner";
import CashaCard from "../components/CashaCard";
import SecurityBanner from "../components/SecurityBanner";
import BlogUpdate from "../components/blogCarousel/BlogUpdate";



const Home = () => {
  return (
    <>
      <TopHeader />
      <OurPartner />
      <div className="container mx-auto p-6 lg:px-20">
        <AjoBanner />
        <CashaCard />
        <SecurityBanner />
        <BlogUpdate />
        <Download />
      </div>
      <Footer />
    </>
  );
};

export default Home;
