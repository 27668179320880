import React from "react";
import LeftAngle from '../../../assets/images/svgs/leftangle.svg'

function PreviousArrow({ onClick }) {
  return (
    <div className="absolute right-[42px] -top-[45px]">
      <div className="cursor-pointer" onClick={onClick}>
        <img src={LeftAngle} width={28} height={28} alt=""/>
      </div>
    </div>
  );
}

export default PreviousArrow;
