import React from "react"
import { socialMediaContacts } from "../../data/social-media-contacts";

const SocialMedia = () => {
  return (
    <div className="flex items-center md:justify-start justify-center gap-3 mt-5">
            {socialMediaContacts.map((contact, index) => (
              <div
                className="cursor-pointer"
                key={index}
                onClick={() => window.open(contact.link, "_blank")}
              >
                <img
                  style={{
                    maxHeight: 20,
                  }}
                  src={contact.icon}
                  height={20}
                  width={20}
                  alt={contact.title}
                />
              </div>
            ))}
          </div>
  );
};

export default SocialMedia;
