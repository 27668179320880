import React from "react";
import AppleLogo from "../../assets/images/apple-logo.png";
import GooglePlayLogo from "../../assets/images/google-play-logo.png";
const Download = () => {
  return (
    <section className="download-casha-section w-full p-11 mt-20 mb-6 rounded-3xl mx-auto">
      <div className="row justify-content-center">
        <div className="col-lg-6 text-center text-white">
          <h3 className=" text-2xl w-10/12 lg:text-4xl ßlg:text-4xl font-semibold mt-2 mb-4 lg:w-3/5 mx-auto">
            Saving on Casha Automated and Safe
          </h3>
          <p className="my-8 font-light xl:w-2/3 mx-auto">
            Save money online with your friends, family and even people from
            your workplace. Rotate the contribution weekly or monthly and a user
            gets the lump weekly or monthly.
          </p>
        </div>
        <div className="text-center flex flex-col lg:flex-row justify-center lg:gap-3 gap-4 items-center">
          <button
            className="flex items-center p-2 w-40  text-black gap-3 justify-center bg-white rounded-lg"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=io.casha.app",
                "_blank"
              )
            }
          >
            <img
              src={GooglePlayLogo}
              className=""
              height={18}
              width={18}
              alt="Casha app logo on play store"
            />

            <div className="w-20 flex flex-col text-left">
              <span className="text-xsm">Download on </span>
              <span className="text-sm font-bold"> Google Play</span>
            </div>
          </button>
          <button
            className="flex items-center w-40 p-2 text-black gap-3 justify-center bg-white rounded-lg"
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/casha/id1673633904",
                "_blank"
              )
            }
          >
            <img
              src={AppleLogo}
              className=""
              height={20}
              width={20}
              alt="Cash app logo on playstore and app store"
            />

            <div className="w-20 flex flex-col text-left">
              <span className="text-xsm">Download on </span>
              <span className="text-sm font-bold"> App Store</span>
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Download;
