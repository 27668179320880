import { Routes, Route } from "react-router-dom";
import {Helmet} from 'react-helmet'
import Home from "./pages/home/Home";
import Feature from "./pages/feature/Feature";
import Finances from "./pages/finances/Finances";
function App() {
  return (
    <>
    <Helmet>
      <title>Casha!</title>
      <meta name="Casha personal saving and group contribution"  content="Casha is a fintech app built for personal saving, group Ajo contribution,adashe,bills payment
      esusu,rotational savings and wealth management"/>
    </Helmet>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/ajo-features" element={<Feature />}></Route>
        <Route path="/finances-blog" element={<Finances />}></Route>
      </Routes>
    </>
  );
}

export default App;
