import React from "react";

function CashaCard() {
  return (
    <div className=" columns-1 lg:columns-2 gap-8 space-y-8 w-full lg:w-full mx-auto my-12">
      <div className="">
        <div className="w-full h-[500px]  bg-[#fef6f5] bg-[url('./assets/images/svgs/pay-bill.png')] bg-no-repeat bg-right-bottom bg-[length:270px_auto] lg:bg-[length:330px_auto] p-12 rounded-3xl">
          <div className="py-3 flex items-center ">
            <hr
              style={{ width: "12px" }}
              className="border-[#F1F6F0] border-1"
            />
            <small className="text-[#cca08f] ms-1 text-xsm">
              {" "}
              PAY BILLS IN A JIFFY
            </small>
          </div>
          <h3 className="text-2xl font-semibold lg:text-3xl leading-10">
            With Casha pay all your recuring bills
          </h3>
          <p className=" text-base text-[#707070] py-4">
            Whether it's your electricity, TV or internet bills we have got you
            covered.
          </p>
        </div>
      </div>
      {/* FAMILY AND FRIEND CARD */}
      
      <div className="">
        <div className="w-full h-[520px] bg-[#f1f6ef] bg-[url('./assets/images/svgs/family-img.svg')] bg-no-repeat bg-right-bottom bg-[length:330px_auto] lg:bg-[length:400px_auto] p-12 rounded-2xl">
          <div className="py-3 flex items-center ">
            <hr style={{ width: "12px" }} className="border-[#cca08f] border-1" />
            <small className="text-[#cca08f] ms-1 text-sm">FAMILY & FRIENDS</small>
          </div>
          <h3 className="text-2xl font-semibold lg:text-3xl leading-10">
            Stay connected to family and friends
          </h3>
          <p className="text-base text-[#707070] py-4">
            Invite your loved ones to use Casha to send and receive money fast and easy
          </p>
        </div>
      </div>

     {/* SEND MONEY CARD */}

      <div className="">
        <div className="w-full h-[550px] bg-[#FAF8EE] bg-[url('./assets/images/svgs/send-money.svg')] bg-no-repeat bg-bottom bg-[length:415px_auto] lg:bg-[length:490px_auto] p-12 rounded-3xl">
          <div className="py-3 flex items-center ">
            <hr
              style={{ width: "12px" }}
              className=" border-[#F5BF54] border-1"
            />
            <small className="text-[#F5BF54] ml-1">
              {" "}
              SEND AND RECEIVE MONEY
            </small>
          </div>
          <h3 className="text-2xl font-semibold lg:text-3xl leading-10">
            Moving money couldn't be any easier
          </h3>
          <p className=" text-base py-4 text-[#707070]">
            Send and receive money in seconds with Casha
          </p>
        </div>
      </div>

      {/* USSD SERVICE */}

      <div className="">
        <div className="w-full h-[470px] bg-[#eeeff8] bg-[url('./assets/images/svgs/simple-transaction.svg')] bg-no-repeat bg-right-bottom bg-[length:340px_auto] lg:bg-[length:400px_auto] p-12 rounded-xl">
          <div className="py-3 flex items-center ">
            <hr style={{ width: "12px" }} className="border-[#707070] border-1" />
            <small className="text-[#707070] ml-1"> USSD SERVICE</small>
          </div>
          <h3 className="text-2xl font-semibold lg:text-3xl lg:leading-10">simple & fast transactions</h3>
          <p className="text-sm w-84 lg:py-4 text-[#707070]">
            Send and receive money in just a few clicks anytime, anywhere on any device,
            use our USSD code *347*40#
          </p>
        </div>
      </div>
    </div>
  );
}

export default CashaCard;
