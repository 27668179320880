import React, { useState } from "react";
import cashaLogo from "../assets/images/svgs/casha-logo.svg";
import SocialMedia from "../pages/components/SocialMedia";
import { Link } from "react-router-dom";
import Hamburger from "../assets/images/hamburger.png";
import Cross from "../assets/images/cross-hambuger.png";

const Nav = () => {
  return (
    <div className="w-full block " id="navbar">
      <MobileNav />
    </div>
  );
};

const MobileNav = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <div className="nav-bar container flex items-center justify-between px-6 pt-16 pb-6 w-full text-xs mx-auto">
        <div className="w-1/4">
        <a className="text-decoration-none" href="/">
                
                 <img
            src={cashaLogo}
            width={160}
            height={80}
            className="my-2 cursor-pointer"
            alt=""
          />
          </a>
        </div>

        <div
          onClick={() => setShowSidebar(!showSidebar)}
          className="w-14 h-14 bg-[#eef2f5] rounded-full flex justify-center items-center cursor-pointer "
        >
          <img src={Hamburger} alt="" height={20} width={25} />
        </div>
      </div>

      {/* ----- SIDEBAR ------- */}

      <div className="flex flex-col items-center justify-center min-h-screen py-2 absolute">
        <div
          className={`top-0 right-0 w-full flex justify-between flex-col ease-in-out duration-0 bg-[url('/src/assets/images/bg-header.png')] bg-cover bg-center p-4 py-10 h-screen fixed z-40 ${
            showSidebar ? "translate-x-0 " : "translate-x-full"
          }`}
        >
          <div className="nav-bar container flex items-center justify-between p-5 w-full text-xs mx-auto">
            <div className="w-1/4">
              <img
                src={cashaLogo}
                width={160}
                height={80}
                className="my-2"
                alt=""
              />
            </div>

            <div
              onClick={() => setShowSidebar(!showSidebar)}
              className="w-14 h-14 bg-[#c0dbec] rounded-full flex justify-center items-center cursor-pointer"
            >
              <img src={Cross} alt="" height={20} width={25} />
            </div>
          </div>

          <div className="flex flex-col items-center mt-24 gap-[12rem] text-black">
            <ul className="flex flex-col items-center gap-11 text-2xl lg:text-5xl font-semibold">
              <li className="hover:text-cashagreen cursor-pointer">
                <Link to="/">Home</Link>
              </li>
              <li className="hover:text-cashagreen cursor-pointer">
                <Link to="/ajo-features">How the ajó feature works</Link>
              </li>
              <li className="hover:text-cashagreen cursor-pointer">
                <a href="#contact" onClick={() => setShowSidebar(false)}>
                  Contact
                </a>
              </li>
            </ul>

            <div className="pb-12">
              <small className="text-sm">Get in touch with us</small>
              <SocialMedia />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
