import React from "react";
import { Link,useNavigate} from "react-router-dom";

function ImageCard({ img, headline, time }) {
  const navigate = useNavigate()
  const handleReloadClick = () => {
    window.location.reload(navigate("/finances-blog"));
  };
  return (
    <div className="w-[100%] overflow-x-hidden">
      <div className="overflow-hidden rounded-2xl w-100 group relative h-[250px]">
        <img
          src={img}
          width={600}
          className="transition-transform group-hover:scale-110 duration-200 h-full w-full object-cover overflow-hidden"
          alt=""
        />
      </div>
      <div className="flex flex-col items-start gap-4 py-8">
        <div>
          <Link onClick={handleReloadClick} className="decoration-none">
            <h1 className=" text-lg font-bold hover:underline hover:text-cashagreen">
              {headline}
            </h1>
          </Link>
        </div>
        <div>
          <small className="text-sm">{time}</small>
        </div>
      </div>
    </div>
  );
}

export default ImageCard;
