import React from 'react'
import cashaLogo from "../../assets/images/svgs/casha-icon.svg";

function TopAjoBanner() {
  return (
    <section className=" w-full lg:w-2/3 text-center build-for-you-section-info py-9 items-center mx-auto flex flex-col my-16">
      <img
        src={cashaLogo}
        className="mt-4 w-16"
        height={60}
        alt="Casha app logo"
      />
      <div>
        <div className="py-3 flex items-center justify-center ">
          <hr
            style={{ width: "15px" }}
            className="border-green-600 border-1"
          />
          <small className="text-cashagreen mx-1">WHY CASHA</small>
          <hr
            style={{ width: "15px" }}
            className="border-green-600 border-1"
          />
        </div>
      </div>
      <h3 className="text-3xl font-semibold tracking-wide text-primary">
        What can you do on casha
      </h3>
    </section>
  )
}

export default TopAjoBanner