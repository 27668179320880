import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../../shared/Nav";
import MyAjoGroupsScreenshot from "../../assets/images/my-ajo-groups-screenshot.png";
import HeroScreenshot from "../../assets/images/hero-screenshot.png";
import SignUpScreenshot from "../../assets/images/signup-screenshot.png";
import DashboardView from "../../assets/images/dashboard-view.png";
import CreateAjoView from "../../assets/images/create-ajo-view.png";
import cashaLogo from "../../assets/images/svgs/casha-icon.svg";
import rocket from "../../assets/images/rocket.png";
import face from "../../assets/images/face.png";
import Download from "../components/Download";
import GooglePlayLogo from "../../assets/images/svgs/white-google-play-logo.svg";
import AppleLogo from "../../assets/images/svgs/apple-logo.svg";
import Footer from "../components/Footer";

const Feature = () => {
  return (
    <div>
      <Helmet>
      <title>Ajo features</title>
      <meta name="Casha Ajo personal and group savings features"  content="Casha is a fintech app built for personal saving, group Ajo contribution,adashe,bills payment
      esusu,rotational savings and wealth management"/>
    </Helmet>
      <div className="hero bg-[url('./assets/images/feature-hero-bg.png')] lg:h-screen h-1/2 flex flex-col relative items-center ">
        <div className="container mx-auto p-4">
          {" "}
          <Nav />
        </div>
        <img src={HeroScreenshot} className="w-fit h-3/4 text-white" />
        <section className="absolute bottom-0 bg-white p-4">
          <div className="flex flex-col lg:flex-row items-center justify-center lg:border lg:p-10 gap-10 lg:gap-40">
            <div className="lg:w-1/2 w-full">
              <h1 className=" text-xl lg:text-4xl font-bold leading-snug">
                Why take loans when you can{" "}
                <span className="emphasis-word-big">join</span> an ajó group?
              </h1>
            </div>
            <div className="w-full lg:w-1/2">
              <p className="font-light">
              Create an ajó contribution group in less than 5 minutes. Invite your friends,<br/>
               family and co-workers to contribute daily, weekly or monthly with you.
              </p>
              <div className="flex item">
              <button className="w-55 h-10 mr-5 flex items-center p-4 mt-5 text-xs text-white gap-2 justify-center bg-primary rounded-lg"   onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=io.casha.app",
                    "_blank"
                  )
                }>
                <img src={GooglePlayLogo} className="" height={20} width={20} />
                <span>Download on<br/> <span className="font-bold text-small">Google play</span></span>
              </button>
              <button className="w-55 h-10 flex items-center p-4 mt-5 text-xs text-white gap-2 justify-center bg-primary rounded-lg"   onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/casha/id1673633904",
                    "_blank"
                  )
                }>
                <img src={AppleLogo} className="" height={20} width={20} />
                <span>Download on<br/> <span className="font-bold text-small">Apple store</span></span>
              </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="">
        <section className="p-4">
          <div className=" flex flex-col lg:flex-row items-center justify-center mx-auto lg:p-10 lg:gap-40 gap-10 lg:w-4/5 w-full">
            <div className="lg:w-1/2 w-full">
              <div>
                <div className="py-3 flex items-center">
                  <hr
                    style={{ width: "15px" }}
                    className="border-green-600 border-1"
                  />
                  <small className="text-green-600 mx-1">WE ANSWER THE WHY</small>
                </div>
              </div>
              <h5 className="text-xl font-semibold leading-snug mt-4">
              You’re wondering if you can trust casha! to manage your ajó
              </h5>
              <p className="mt-3">
              We’ve put a lot of security measures in place that ensures only members <br/>
              that actively contribute in the group receive payout. 
              Don’t worry your money is always safe with us.
              </p>
              <div className="flex item mt-4">
              <button className="w-55 h-10 mr-5 flex items-center p-4 mt-5 text-xs text-white gap-2 justify-center bg-primary rounded-lg"   onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=io.casha.app",
                    "_blank"
                  )
                }>
                <img src={GooglePlayLogo} className="" height={20} width={20} />
                <span>Download on<br/> <span className="font-bold text-small">Google play</span></span>
              </button>
              <button className="w-55 h-10 flex items-center p-4 mt-5 text-xs text-white gap-2 justify-center bg-primary rounded-lg"   onClick={() =>
                 window.open(
                  "https://apps.apple.com/us/app/casha/id1673633904",
                  "_blank"
                )
                }>
                <img src={AppleLogo} className="" height={20} width={20} />
                <span>Download on<br/> <span className="font-bold text-small">Apple store</span></span>
              </button>
              </div>
            </div>
            <div className="lg:w-1/2 w-full">
              <img src={MyAjoGroupsScreenshot} className="" height={60} />
            </div>
          </div>
        </section>

        <section className="lg:w-2/3 text-center build-for-you-section-info lg:py-10 pt-28 p-4 items-center mx-auto flex flex-col">
          <img src={cashaLogo} className="mt-4 w-16" height={60} />
          <div>
            <div className="lg:py-3 flex items-center justify-center">
              <hr style={{ width: "20px" }} className="border-green-600 border-1" />
              <small className="text-green-600 mx-1">WE BUILT THIS FOR YOU</small>
              <hr style={{ width: "20px" }} className="border-green-600 border-1" />
            </div>
          </div>
          <h3 className="text-2xl font-bold mt-2">
            How does ajó on Casha! ™ work?
          </h3>
          <p className="fw-light text-center">
          Casha’s ajó process was designed to make creating
          and maintaining an ajó group as seamless and<br/> familiar as 
          possible withe the added layer of protection to keep all member contribution secure.
          </p>
        </section>

        <section className="p-4">
          <div className=" flex lg:flex-row flex-col items-center justify-center mx-auto lg:p-10 lg:gap-40 gap-10 lg:w-4/5 w-full">
            <div className="lg:w-1/2 w-full">
              <img src={SignUpScreenshot} className="" height={60} />
            </div>

            <div className="lg:w-1/2 w-full">
              <img src={rocket} className="mt-4 w-16" height={60} />
              <h5 className="text-xl text-primary font-semibold leading-snug my-5">
                Sign up on Casha!™
              </h5>
              <p className="text-primary">
                After downloading Casha! ™, sign up by verifying your phone
                number and your identity. You can start an ajó group on the Home
                Screen.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-[url('./assets/images/colored-bg.png')]  bg-auto bg-no-repeat p-2">
          <div className=" flex lg:flex-row flex-col items-center justify-center mx-auto lg:py-24 py-10 lg:gap-40 gap:10 lg:w-4/5 w-full">
            <div className="lg:w-1/2">
              <img src={rocket} className="mt-4 w-16" height={60} />
              <h5 className="text-xl font-semibold leading-snug my-4">
                Create an Ajó group
              </h5>
              <p>
                Kick off creating your ajó group by naming it. Now add a<br/> total
                payout amount, payment frequency and<br/> contribution amount.
              </p>
            </div>
            <div className="lg:w-1/2 w-full">
              <img src={DashboardView} className="" height={60} />
            </div>
          </div>
        </section>

        <section className="p-4">
          <div className=" flex lg:flex-row flex-col items-center justify-center mx-auto lg:p-10 lg:gap-40 gap-10 lg:w-4/5 w-full">
            <div className="lg:w-1/2">
              <img src={CreateAjoView} className="" height={60} />
            </div>
            <div className="lg:w-1/2">
              <img src={face} className="mt-4 w-16" height={60} />
              <h5 className="text-xl font-semibold leading-snug my-4">
                Invite your friends!
              </h5>
              <p>
                Now all that is left is to invite your friends, family or
                co-workers and enjoy the seamless experience of contributing on
                Casha! ™
              </p>
            </div>
          </div>
        </section>
        {/* FAQs */}
        <section className="lg:w-2/3 mx-auto py-14 pt-32 faq-section-info p-4">
          <div>
            <div className="py-3 flex items-center">
              <hr style={{ width: "15px" }} className="border-primary border-1" />
              <small className="text-primary mx-1">WE’VE GOT THE ANSWERS</small>
            </div>
          </div>
          <h3 className="text-2xl font-bold mt-2">
            Frequently asked questions
          </h3>

          <div className="grid lg:grid-cols-3 grid-cpls-1 gap-4 my-4">
            <div className="mb-3">
              <h6 className=" mb-4 font-bold">How can I sign up on Casha!?</h6>
              <p className="leading-relaxed text-xs">
                Download the app from the Play Store and enter your phone
                number. If you do not have a smartphone or internet connection,
                you can dial *347*40# to sign up.
              </p>
            </div>
            <div className="mb-3">
              <h6 className=" mb-4 font-bold">What can I do on Casha!?</h6>
              <p className="leading-relaxed text-xs">
                You can send and receive money, start a contribution group, buy
                airtime, pay your electricity bills and your TV
                subscription(GoTV, Startimes, DSTV)
              </p>
            </div>
            <div className="mb-3">
              <h6 className=" mb-4 font-bold">
                How can I fund my Casha! Account?
              </h6>
              <p className="leading-relaxed text-xs">
                You can share your Casha! account details with your customers,
                friends and family. You can also fund from POS or your other
                bank
              </p>
            </div>
            <div className="mb-3">
              <h6 className=" mb-4 font-bold">How do I join an Ajo group?</h6>
              <p className="leading-relaxed text-xs">
                You can join an Ajo group by invitation. You can also create
                your own Ajo group and invite others.
              </p>
            </div>
            <div className="mb-3">
              <h6 className=" mb-4 font-bold">
                How many people can I invite to my Ajo group?
              </h6>
              <p className="leading-relaxed text-xs">
                For the time being, only 10 people (including yourself)
              </p>
            </div>
            <div className="mb-3">
              <h6 className=" mb-4 font-bold">
                Can I contribute to an Ajo group daily?
              </h6>
              <p className="leading-relaxed text-xs">
                Yes! While setting up the group, you can choose contribution
                frequency: daily, weekly or monthly
              </p>
            </div>
            <div className="mb-3">
              <h6 className=" mb-4 font-bold">
                How do I pay into my Contribution group?
              </h6>
              <p className="leading-relaxed text-xs">
                All you need to do is put money into your Casha! Wallet then the
                Ajo group would automatically debit you.
              </p>
            </div>
             
          </div>
          {/* FAQs */}
        <Download />
        </section>
       
      </div>
      <Footer />
    </div>
  );
};

export default Feature;
