import React from 'react'
import { Helmet } from 'react-helmet';
import driverImage from "../../assets/images/grid/driver.png";
import img5 from "../../assets/images/grid/img-5.png";
import img1 from "../../assets/images/grid/img-1.png";
import img2 from "../../assets/images/grid/img-2.png";
import img4 from "../../assets/images/grid/img-4.png";
import img6 from "../../assets/images/grid/img-6.png";
import img3 from "../../assets/images/grid/img-3.png";
import Download from "../components/Download";
import Footer from '../components/Footer';
import Nav from '../../shared/Nav';
function Finances() {
  return (
    <div>
      <Nav/>
        
        <Helmet>
      <title>Finances-blog</title>
      <meta name="Casha finances system and management"  content="contribution,adashe,bills payment
      esusu,rotational savings and wealth management"/>
    </Helmet>
         <section className="mt-5 lg:w-2/3 text-center lg:py-10 pt-28 p-4 items-center mx-auto flex flex-col">
          <div>
            <div className="lg:py-3 flex items-center justify-center">
            
            </div>
          </div>
          <h3 className="text-3xl font-bold mb-5">
          The Impact of Inflation on Personal<br className="hidden sm:block"/> Finances
          </h3>
          <p className="fw-light text-center mb-2">
          Explore effective strategies such as investing in inflation-protected assets
           or diversifying
            your portfolio to safeguard your wealth against inflationary pressures.
          </p>
          <img className="mt-5" src={driverImage} alt="Save money now imag"/>
        </section>
        <div className='sm:ml-18rem ml-5 mb-5'>
            <h5 className=' text-lg font-bold'>Introduction</h5>
            <p className=' '>Cryptocurrency has emerged as a groundbreaking phenomenon, 
                transforming the way we perceive and transact with money. 
                With its<br/> decentralized nature and utilization of blockchain technology,
                 cryptocurrencies offer unique opportunities and challenges for investors<br/> and enthusiasts alike. In this blog post, we will delve into the basics of cryptocurrencies, explore the potential benefits and risks, 
                and<br/> provide practical guidance for navigating the digital asset landscape.</p>
                </div>
                <section className='mb-20'>      
        <div className="mt-0 mb-0 grid grid-rows-[repeat(12,_minmax(0,_1fr))]  grid-cols-11 gap-4 max-h-[500px] h-[500px] w-full lg:w-2/3 mx-auto my-24  text-white text-sm">
          <div className=" row-start-1 row-end-7 col-start-1 col-end-3 overflow-hidden">
            <img
              className="object-cover h-full w-full object-center rounded-lg"
              src={img1}
              alt="Cash user image 1"
            />
          </div>
          <div className="row-span-5 col-start-3 col-end-7">
            <img
              className="object-cover h-full w-full object-center rounded-lg"
              src={img2}
              alt="Casha user image 2"
            />
          </div>
          <div className="row-span-5 col-start-7 col-end-12">
            <img
              className="object-cover h-full w-full object-center rounded-r-lg"
              src={img3}
              alt="Casha user image 3"
            />
          </div>
          <div className="row-start-7 row-end-[12] col-start-1 col-end-3">
            <img
              className="object-cover h-full w-full object-right rounded-lg"
              src={img4}
              alt="Casha user image 4"
            />
          </div>
          <div className="row-end-[12] row-start-6 col-start-3 col-end-9">
            <img
              className="object-cover h-full w-full object-center rounded-lg"
              src={img5}
              alt="Casha user image 5"
            />
          </div>
          <div className="row-span-6 row-start-6 col-start-9 col-end-12">
            <img
              className="object-cover h-full w-full object-center rounded-lg"
              src={img6}
              alt="Casha user image 6"
            />
          </div>
        </div>
        <div className='mt-0 sm:ml-18rem ml-5 mb-5'>
        <h5 className='text-lg font-bold'>What are Cryptocurrencies</h5>
        <p className=' mt-5 '>Cryptocurrencies are digital or virtual currencies that utilize cryptography 
            for secure transactions, control the creation of new units, and<br className="hidden sm:block"/> verify the transfer of assets. 
            We’ll discuss the underlying principles of cryptocurrencies, decentralized networks, 
            and the role of blockchain<br className="hidden sm:block"/> technology in ensuring transparency and security.</p>

            <p className='mt-5'>1. Key Cryptocurrencies and Their Functions<br className="hidden sm:block"/> Explore the most prominent cryptocurrencies, 
                including Bitcoin (BTC), Ethereum (ETH), and others. Learn about their unique features,
                 use<br/> cases, and how they differ from traditional fiat currencies. 
                 We’ll also introduce altcoins and discuss the 
                concept of initial coin offerings<br className="hidden sm:block"/> (ICOs) in the cryptocurrency ecosystem.</p>

                <p className='mt-5'>2. Benefits and Risks of Cryptocurrencies Uncover the potential advantages of cryptocurrencies, 
                    such as faster and cheaper cross-border<br className="hidden sm:block"/> transactions, increased financial inclusion, and the potential for higher returns on investments. 
                    However, we’ll also address the risks, 
                    such<br className="hidden sm:block"/> as price volatility, regulatory uncertainties, and the potential for scams and fraud. </p>

                    <p className='mt-5 '>3. Getting Started with Cryptocurrency<br className="hidden sm:block"/> For beginners, 
                        we’ll provide practical steps to enter the world of cryptocurrencies. 
                        From setting up a secure digital wallet and choosing a<br className="hidden sm:block"/> reputable cryptocurrency exchange to understanding private keys and public addresses, 
                        we’ll guide you through the essential elements<br className="hidden sm:block"/> of safely acquiring and storing digital assets.</p>
        </div>
        
        </section>
        <div className='px-6 lg:px-[10rem]'>
        <Download/>
        
        </div>
        
        <Footer/>
    </div>
  )
}

export default Finances