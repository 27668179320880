import React from "react";
import footerCurve from "../../assets/images/svgs/footer-curve.svg";
import cashaLogo2 from "../../assets/images/svgs/casha-logo.svg";
import SocialMedia from "./SocialMedia";

const Footer = () => {
  return (
    <>
      <hr className="mt-28 mb-16" />
      <footer
        className="text-center border-top w-full lg:w-10/12 mx-auto"
        id="contact"
      >
        <div className="hidden lg:flex flex-col md:flex-row justify-between items-center text-center md:text-start mb-10">
          <div className=" md:w-1/5 w-full my-4">
            <p className=" font-bold mb-3 text-lg">Contact</p>
            <p>
              <a href="mailto:support@casha.io" class="text-cashagreen ">
                support@casha.io
              </a>
            </p>
            <p>
              <a href="tel:+2342012296116" class="text-cashagreen ">
                +234-020-122-96-116
              </a>
            </p>

            <SocialMedia />
          </div>

          <div className="md:w-1/5 w-full my-5">
            <h3 className=" font-bold mb-3 text-lg">Lagos</h3>
            <p className="w-40 mb-2 text-[#6b676b]">
              25b Bisola Durosinmi Etti drive, Lekki Phase 1, Lagos, Nigeria
            </p>
          </div>

          <div className="md:w-1/5 w-full my-5">
            <h3 className=" font-bold mb-3 text-lg">Ibadan</h3>
            <p className="w-50 mb-2 text-[#6b676b]">
              11 Osuntokun Avenue, 200285, Ibadan, Oyo Nigeria
            </p>
          </div>
          <div className="md:w-1/5 w-full my-5">
            <h3 className=" font-bold mb-3 text-lg">Quick Links</h3>

            <ul class="list-none text-[#6b676b]">
              <li>
                {" "}
                <a className="text-decoration-none" href="/">
                  Home
                </a>
              </li>
              <li>
                {" "}
                <a className="text-decoration-none" href="/ajo-features">
                  How the ajo feature works
                </a>
              </li>
              <li>
                <a
                  className="text-decoration-none"
                  href="https://casha-privacypolicy.disha.page/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p>Designed with love by the casha! ™ team</p>
        <a href="#navbar">
        <img
          src={cashaLogo2}
          height={30}
          className=" my-14 mx-auto"
          alt="Casha app logo 2"
        />
         </a>
        <div className="lg:hidden md:hidden">
          <SocialMedia />
        </div>
        <div className="flex justify-center">
          <img
            src={footerCurve}
            height={50}
            className="mt-5"
            alt="Casha footer logo"
          />
       
        </div>
      </footer>
    </>
  );
};

export default Footer;
