import React from 'react'
import cashaLogo from '../../../assets/images/svgs/casha-icon.svg'
import BlogCarousel from './BlogCarousel'


function BlogUpdate() {
  return (
    <div>
        <section className=" w-full mt-24 mb-20 lg:w-2/3 text-center faq-section-info py-5 items-center mx-auto flex flex-col lg:mt-40">
      <img
        src={cashaLogo}
        className="mt-4 w-16"
        height={60}
        alt="Casha app logo"
      />
      <div>
        <div className="py-3 flex items-center justify-center">
          <hr
            style={{ width: "15px" }}
            className="border-cashagreen border-1"
          />
          <small className="text-cashagreen mx-1">News</small>
          <hr
            style={{ width: "15px" }}
            className="border-cashagreen border-1"
          />
        </div>
      </div>
      <h3 className="text-4xl font-semibold my-4 tracking-wider">
        Update from our blogs
      </h3>
      <p className="fw-light text-center text-primary text-base">
       MAX DRIVE Mobility paves the way for the financial 
       inclusion with Launch of New Group Saving Fintech Solution in Africa-CASHA
      </p>
    </section>
    <BlogCarousel />
    </div>
  )
}

export default BlogUpdate