import React from "react";
import Nav from "../../shared/Nav";
import Phone from "../../assets/images/phone-banner.png";
import GooglePlayLogo from "../../assets/images/svgs/white-google-play-logo.svg";
import AppleLogo from "../../assets/images/apple-logo-white.png";
import QrScanner from '../../assets/images/qr-scanner.png'

function TopHeader() {
  return (
    <div className="bg-[url('/src/assets/images/bg-header.png')] bg-cover bg-center lg:h-screen">
      <Nav />
      <section className="h-full ">
        <div className="flex flex-col lg:flex-row items-center justify-center lg:gap-20">
          <div className="w-full flex flex-col py-5 items-center lg:w-2/5 lg:pb-12 lg:items-start">
            <div className="flex flex-col justify-center p-3 text-center lg:p-0 lg:text-left">
              <h1 className="text-4xl leading-[1.3] lg:text-6xl font-bold lg:leading-[1.4] mb-4">
                Your <span className="emphasis-word">{""}gateway</span> to{" "}
                financial freedom!
              </h1>
              <p className="font-light mb-4 lg:text-lg w-82">
                Effortlessly send and receive money in seconds, pay bills and
                participate in our easy automated contribution group!
              </p>
            </div>
            <div className="hidden lg:block">
              <img src={QrScanner} width={150} height={0} alt="" />
            </div>

            <div className="flex gap-2 lg:hidden">
              <button
                className="flex items-center p-4 mt-5 text-xs text-white gap-2 justify-center bg-primary rounded-lg h-12"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=io.casha.app",
                    "_blank"
                  )
                }
              >
                <img
                  src={GooglePlayLogo}
                  className=""
                  height={15}
                  width={15}
                  alt="Casha app logo"
                />
                <div className="w-20 flex flex-col text-left">
                  <span className="w-20 text-xsm text-[#cdc4ca]">
                    {" "}
                    Download on
                  </span>{" "}
                  <span className="font-bold">Google play </span>
                </div>
              </button>
              <button
                className="flex items-center p-4 mt-5 text-xs text-white gap-2 justify-center bg-primary rounded-lg h-12"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/casha/id1673633904",
                    "_blank"
                  )
                }
              >
                <img
                  src={AppleLogo}
                  className=""
                  height={15}
                  width={15}
                  alt="Casha app logo"
                />
                <div className="w-20 flex flex-col text-left">
                  <span className="w-20 text-xsm text-[#cdc4ca]">
                    {" "}
                    Download on
                  </span>{" "}
                  <span className="font-bold">Apple Store </span>
                </div>
              </button>
            </div>
          </div>
          <div className="w-full p-8 mb-9 lg:w-2/5 lg:p-0">
            <img
              className="save-money-now-image"
              src={Phone}
              alt="Save money now imag"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopHeader;
