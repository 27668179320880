import React from 'react'
import RightAngle from '../../../assets/images/svgs/right-angle.svg'

function NextArrow({onClick}) {
  return (
    <div className='absolute right-0 -top-[45px]'>
        <div className='cursor-pointer' onClick={onClick}>
            <img src={RightAngle} width={28} height={28} alt='' />
        </div>
    </div>
  )
}

export default NextArrow