import { partnerImage } from "../../data/social-media-contacts";

function OurPartner() {
  return (
    <div className="flex flex-col lg:flex-row gap-8 items-center lg:gap-32 lg:pl-28 pt-32">
      <div className="text-lg text-dark md:items-center">
        <h1>Our Partners</h1>
      </div>
      <div className="flex md:flex-row flex-wrap justify-center gap-12 item-center lg:gap-28">
        {partnerImage.map((partner) => (
          <img src={partner.icon} key={partner.id} alt="" height={70} width={90} />
        ))}
      </div>
    </div>
  );
}

export default OurPartner;
