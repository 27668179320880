import SecurityKey from '../../assets/images/svgs/security-icon.svg'


function SecurityBanner() {
  return (
    <div className="flex md:flex-row flex-col  justify-between items-center bg-[#262730] rounded-3xl overflow-hidden">
    <div className="w-full lg:w-2/5 md:w-3/5 text-white p-9 m-10">
      <div className="flex items-center gap-1">
        <hr style={{ width: "12px" }} className="border-green-600 border-1" />{" "}
        <small className="text-[#e0d9d9] text-center text-sm tracking-widest">SECURITY</small>
      </div>

      <h1 className=" text-3xl font-semibold my-5">Transactions secure with end-to-end encryption.</h1>
      <p className="text-[#c6bebe]">
        Join thousands of champions taking charge of their finances with Casha!
      </p>
    </div>
    <div>
      <img src={SecurityKey} width={450} height={300} alt="" />
    </div>
  </div>
  )
}

export default SecurityBanner